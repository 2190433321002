if (document.location.host === 'interactive-brokers.ru' || document.location.host === 'ib-nalog.ru') {

  // google
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-L1LG4SZQBS');

  // yandex
  (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  ym(window.yandexCounter, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  });
  window.yandexGoal = (goal, params) => {
    if (undefined !== params) {
      ym(window.yandexCounter, 'reachGoal', goal, params);
    } else {
      ym(window.yandexCounter, 'reachGoal', goal);
    }
  }

} else {

  window.yandexGoal = (goal, params) => {
    console.log("YandexMetric:", goal, params);
  }

}
