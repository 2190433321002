declarationCheckout = function (uuid, urlCheckPromoCode, urlSend, tariffPrice, initPrice, initPromoCode) {
    return {
        uuid,
        urlSend,
        urlCheckPromoCode,
        tariffPrice,
        isTurnstileReady: false,
        isSubmitting: false,
        error: null,
        email: '',
        emailError: '',
        isPromoCodeVisible: false,
        isPromoCodeActive: false,
        promoCode: '',
        promoCodeError: '',
        promoCodeDiscount: 0,
        watchedPreview: false,
        watchedPreviewError: '',
        withoutConsultation: false,
        withoutConsultationError: '',
        publicOffer: false,
        publicOfferError: '',
        privacyPolicy: false,
        privacyPolicyError: '',
        onEmailKeyPress() { this.emailError = ''; },
        onPromoCodeKeyPress() { this.promoCodeError = ''; },
        onWatchedPreviewClick() { this.watchedPreviewError = ''; },
        onWithoutConsultationClick() { this.withoutConsultationError = ''; },
        onPublicOfferClick() { this.publicOfferError = ''; },
        onPrivacyPolicyClick() { this.privacyPolicyError = ''; },
        init() {
            global.onTurnstileReadyHandler(() => this.isTurnstileReady = true);
            const notifyEmail = (localStorage.getItem('notifyEmail')) ?? '';
            const checkoutEmail = (localStorage.getItem('checkoutEmail')) ?? '';
            if (checkoutEmail) {
                this.email = checkoutEmail;
            } else if (notifyEmail) {
                this.email = notifyEmail;
            }
            if (initPromoCode) {
                this.promoCode = initPromoCode;
                this.isPromoCodeVisible = true;
                this.isPromoCodeActive = true;
                this.promoCodeDiscount = tariffPrice - initPrice;
            }
        },
        togglePromoCode() {
            if (this.isPromoCodeVisible) {
                this.hidePromoCode();
            } else {
                this.isPromoCodeVisible = true;
                this.$nextTick(() => this.$refs.promoCode.focus());
            }
        },
        hidePromoCode() {
            this.promoCode = '';
            this.promoCodeError = '';
            this.promoCodeDiscount = 0;
            this.isPromoCodeVisible = false;
            this.isPromoCodeActive = false;
        },
        applyPromoCode() {
            fetch(this.urlCheckPromoCode, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-declaration-id': this.uuid,
                },
                body: JSON.stringify({
                    code: this.promoCode,
                    token: global.turnstileToken,
                }),
            }).then(x => x.json()).then(x => {
                this.isTurnstileReady = false;
                global.onloadTurnstileCallback();
                if (x.discount) {
                    this.promoCode = this.promoCode.toUpperCase();
                    this.promoCodeDiscount = x.discount;
                    this.isPromoCodeActive = true;
                } else {
                    this.promoCodeError = 'Промокод не найден';
                    this.isPromoCodeActive = false;
                }
            })
        },
        getDiscount() {
            return -1 * this.promoCodeDiscount;
        },
        getDiscountWithSign() {
            return formatPrice(this.getDiscount());
        },
        getPrice() {
            return this.tariffPrice + this.getDiscount();
        },
        getPriceWithSign() {
            return formatPrice(this.getPrice());
        },
        send() {
            this.error = null;

            this.emailError = this.email ? '' :
                'Введите адрес электроной почты';
            this.watchedPreviewError = this.watchedPreview ? '' :
                'Проверьте расчет по расчету налоговой базы';
            this.withoutConsultationError = this.withoutConsultation ? '' :
                'Подтвердите что согласны с тем, что консультации не оказываются';
            this.publicOfferError = this.publicOffer ? '' :
                'Без согласия с офертой невозмжно создание декларации';
            this.privacyPolicyError = this.privacyPolicy ? '' :
                'Без согласия с обработкой персональных данных невозмжно создание декларации';
            if (this.emailError || this.watchedPreviewError || this.withoutConsultationError || this.publicOfferError || this.privacyPolicyError) {
                return;
            }

            this.isSubmitting = true;
            global.yandexGoal('send_checkout');
            fetch(this.urlSend, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-declaration-id': this.uuid,
                },
                body: JSON.stringify({
                    email: this.email,
                    promoCode: this.isPromoCodeActive ? this.promoCode : null,
                    token: global.turnstileToken,
                }),
            }).then(x => x.json()).then(x => {
                if (x.error) {
                    this.error = x.error;
                    this.isSubmitting = false;
                } else {
                    localStorage.setItem('checkoutEmail', this.email);
                    window.location.assign(x.url);
                }
            })
        },
    }
}

function formatPrice(price) {
    return formatNumber(price) + '&nbsp;<span class="rub">i</span>';
}

const formatNumberRE = /(\d)(?=(\d\d\d)+(?!\d))/g;
function formatNumber(num)
{
    return ("" + num).replace(formatNumberRE, function($1) { return $1 + '&nbsp;' });
}
